import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError,
} from 'axios';
import { merge } from 'lodash';
import * as ynomiaCore from '@ynomia/core';
import { Bootstrap } from '@ynomia/core/dist/blueprint';
import {
  Response,
  TemporaryWorkaroundResponse,
  RequestConfig,
  RequestMeta,
  PendingRequestQueue,
  RequestError,
} from './interfaces';
import {
  ACCESS_REVOKED,
  NETWORK_ERROR_FALLBACK_MESSAGE,
  NETWORK_TIMEOUT_FALLBACK_MESSAGE,
} from './config/constants';
import { defaultHttpHeaders, immutableHttpHeaders } from './utils';

import SessionManager from './SessionManager';
import InMemoryCache from './InMemoryCache';

/**
 * This class is used for making API requests to the Ynomia backend. You must initialize
 * it with a session and an in-memory-cache store.
 */
export default class Server {
  pendingRequests: PendingRequestQueue = {};
  private session: SessionManager;
  private cache: InMemoryCache;
  private http: AxiosInstance = axios.create();
  private requestId: number = 0;

  constructor(session: SessionManager, cache: InMemoryCache) {
    this.session = session;
    this.cache = cache;
    this.http.interceptors.request.use(this.httpRequestMiddleware);
  }

  /**
   * Performs an HTTP request with the provided configuration.
   * @param {RequestConfig} config
   * @param {RequestMeta} meta: Request metadata used for mostly internal purposes.
   * @return {Promise<Response>}
   * @throws {RequestError}
   */
  async request(config: RequestConfig, meta?: RequestMeta): Promise<Response> {
    try {
      const response: any = await this.http.request(config);
      return this.digestRequestResponse(response);
    } catch (error) {
      return this.digestRequestError(error, config, meta);
    }
  }

  /**
   * Convenience method for performing GET requests.
   * @param {string} url
   * @param {Partial<RequestConfig>} config
   * @return {Promise<Response>}
   * @throws {RequestError}
   */
  async get(
    url: string,
    config: Partial<RequestConfig> = {},
  ): Promise<Response> {
    const response = await this.request({
      method: 'GET',
      url,
      ...config,
    });
    return response;
  }

  /**
   * Convenience method for performing DELETE requests.
   * @param {string} url
   * @param {Partial<RequestConfig>} config
   * @return {Promise<Response>}
   * @throws {RequestError}
   */
  async delete(
    url: string,
    config: Partial<RequestConfig> = {},
  ): Promise<Response> {
    const response = await this.request({
      method: 'DELETE',
      url,
      ...config,
    });
    return response;
  }

  /**
   * Convenience method for performing POST requests.
   * @param {string} url
   * @param {any} data: Body data to send as JSON
   * @param {Partial<RequestConfig>} config
   * @return {Promise<Response>}
   * @throws {RequestError}
   */
  async post(
    url: string,
    data: any = {},
    config: Partial<RequestConfig> = {},
  ): Promise<Response> {
    const response = await this.request({
      method: 'POST',
      url,
      data,
      ...config,
    });
    return response;
  }

  /**
   * Convenience method for performing PUT requests.
   * @param {string} url
   * @param {any} data: Body data to send as JSON
   * @param {Partial<RequestConfig>} config
   * @return {Promise<Response>}
   * @throws {RequestError}
   */
  async put(
    url: string,
    data: any = {},
    config: Partial<RequestConfig> = {},
  ): Promise<Response> {
    const response = await this.request({
      method: 'PUT',
      url,
      data,
      ...config,
    });
    return response;
  }

  /**
   * Convenience method for performing PATCH requests.
   * @param {string} url
   * @param {any} data: Body data to send as JSON
   * @param {Partial<RequestConfig>} config
   * @return {Promise<Response>}
   * @throws {RequestError}
   */
  async patch(
    url: string,
    data: any = {},
    config: Partial<RequestConfig> = {},
  ): Promise<Response> {
    const response = await this.request({
      method: 'PATCH',
      url,
      data,
      ...config,
    });
    return response;
  }

  /**
   * Fetches the latest user, permissions and project information from the backend.
   * This is then inserted into the client cache.
   * @param {string?} projectId: Specify a specific project to fetch the data for. Defaults to
   * the first project in the list of projects that the user has access to.
   * @param {object?} config: Additional configuration for the bootstrap request.
   * @param {boolean?} config.layers: Include Layers in the bootstrap response.
   * @param {boolean?} config.ids: Include Project ID's in the bootstrap response.
   * @return {Promise<Bootstrap>}
   */
  async bootstrap(
    projectId?: string,
    config: { layers?: boolean, ids?: boolean } = {},
  ): Promise<Bootstrap> {
    const apiPath = projectId ? `/scratch/project/bootstrap/${projectId}` : '/scratch/project/bootstrap';
    const response = await this.get(apiPath, {
      params: {
        layers: config.layers === false ? '0' : '1',
        ids: config.ids === false ? '0' : '1',
      },
    });
    const bootstrap: Bootstrap = response.data.value;
    this.session.ingestBootstrapData(bootstrap);
    return bootstrap;
  }

  /**
   * @private
   * This method is called each time an HTTP request is made through the axios request
   * instance to make any final adjustments or data decorations before it is actually sent.
   * @param {AxiosRequestConfig} config: A full Axios request configuration object.
   * @return {AxiosRequestConfig} With any final modifications made.
   */
  private httpRequestMiddleware = (config: AxiosRequestConfig): AxiosRequestConfig => {
    const cancelToken = axios.CancelToken.source();
    this.requestId += 1;
    const headers = merge(
      defaultHttpHeaders(this.cache.current),
      config.headers,
      {
        'X-Client-Request-ID': `${this.requestId}`,
      },
      immutableHttpHeaders(this.cache.current),
    );
    const parsedConfig: AxiosRequestConfig = {
      ...config,
      baseURL: config.baseURL || this.cache.current.baseURL,
      headers,
      cancelToken: cancelToken.token,
    };
    this.pendingRequests[`${this.requestId}`] = {
      startedAt: Date.now(),
      config: parsedConfig,
      cancelToken,
    };
    return parsedConfig;
  };

  /**
   * @private
   * Clears a completed request from the outgoing "pending request" queue.
   * @param {string?} requestId
   * @return {void}
   */
  private removePendingRequest(requestId: string | undefined): void {
    if (requestId && this.pendingRequests[requestId]) {
      delete this.pendingRequests[requestId];
    }
  }

  /**
   * @private
   * Cancels and removes all current requests in-motion. Note that this will trigger the
   * error handler for each of the pending requests.
   * @return {void}
   */
  private cancelAllPendingRequests(): void {
    Object.keys(this.pendingRequests).forEach((requestId: string): void => {
      try {
        this.pendingRequests[requestId].cancelToken.cancel(ACCESS_REVOKED);
      } catch {
        // Do nothing.
      }
      this.removePendingRequest(requestId);
    });
  }

  /**
   * @private
   * Parses the response data from an axios request and returns formatted raw data for
   * the client.
   * @param {AxiosResponse} response
   * @return {Response}
   */
  private digestRequestResponse(response: AxiosResponse): Response {
    this.removePendingRequest(response.config?.headers?.['X-Client-Request-ID']);
    // @fixme Temporary workaround for Pivotal Bug Ticket #173682783. Remove when completed.
    if (Array.isArray(response.data)) {
      return {
        status: response.status,
        data: response.data as unknown as TemporaryWorkaroundResponse,
        headers: response.headers,
      };
    }
    // end @fixme
    const responseError = response.data.err
      ? new ynomiaCore.http.ResponseError(response.data.err.code, response.data.err.msg)
      : null;
    let responseData;
    try {
      responseData = new ynomiaCore.http.Response(response.data.value || {}, responseError);
    } catch {
      responseData = new ynomiaCore.http.Response({}, responseError);
    }
    return {
      status: response.status,
      data: responseData as unknown as TemporaryWorkaroundResponse, // @fixme remove type casting
      headers: response.headers,
    };
  }

  /**
   * @private
   * Attempts to replay a previously failed network request after making some minor
   * adjustments or corrections to areas of the client state. This logic will only kick
   * in under specific circumstances; all other request errors will fail immediately.
   * @param {AxiosError} error
   * @param {RequestConfig} config
   * @param {RequestMeta} meta
   * @return {Promise<Response>} Only if the request was replayed successfully.
   * @throws {ClientError} <-- Most common result.
   */
  private async digestRequestError(
    error: AxiosError,
    config: RequestConfig,
    meta?: RequestMeta,
  ): Promise<Response> {
    this.removePendingRequest(error.request?.headers?.['X-Client-Request-ID']);
    const parsedError = Server.ParseRequestError(error);

    // 1. If the user has become unauthorized, let's try and renew their token and then
    // replay the original request again.
    if (
      parsedError.status === 401
      && this.cache.current.auth.isAuthenticated
      && !meta?.isPostAuthRenewal // <-- Prevents an infinite loop!
    ) {
      await this.session.refresh({
        bootstrap: true,
        projectId: this.cache.current.projectId,
      });
      return new Promise((resolve) => {
        // Conservatively compensates for a potential race condition with the token cache being
        // updated through parallel requests that also received a 401 at roughly the same time.
        setTimeout(() => {
          resolve(this.request(config, { isPostAuthRenewal: true }));
        }, 500);
      });
    }

    // 2. Have we just encountered another 401 after attempting a token renewal?
    if (meta?.isPostAuthRenewal && parsedError.status === 401) {
      this.session.destroy();
      this.cancelAllPendingRequests();
    }

    // No replay request criteria could be satisfied above.
    throw parsedError;
  }

  /**
   * @private
   * Parses the AxiosError into our own error format.
   * @param {AxiosError} error
   * @return {RequestError}
   */
  private static ParseRequestError(error: AxiosError): RequestError {
    if (error.response) {
      const responseData: ynomiaCore.http.Response = error.response.data;
      return {
        status: error.response.status,
        body: new ynomiaCore.http.ResponseError(
          responseData.err?.code || ynomiaCore.http.ErrorCodes.INTERNAL,
          responseData.err?.msg || NETWORK_ERROR_FALLBACK_MESSAGE,
        ),
      };
    }
    return {
      status: -1,
      body: new ynomiaCore.http.ResponseError(
        ynomiaCore.http.ErrorCodes.TIMEOUT,
        NETWORK_TIMEOUT_FALLBACK_MESSAGE,
      ),
      clientError: error.message,
    };
  }
}
