import { InMemoryCacheData } from '../interfaces';

/**
 * Defines the scope of data to load when signing in as a user.
 * - `openid` (indicates that the application intends to use OIDC to verify identity)
 * - `email` (returns the user's email address)
 * - `profile` (limited profile information)
 */
export const DEFAULT_AUTH0_LOGIN_SCOPE = 'openid email profile offline_access';

/**
 * This is the default domain name to make API calls to Auth0 through.
 */
export const DEFAULT_AUTH0_DOMAIN = 'ynomia.au.auth0.com';

/**
 * The fixed key for all issued JWT's which contains custom application metadata.
 */
export const AUTH0_JWT_METADATA_KEY = 'https://yn.io/client_metadata';

/**
 * Auth0 Application Names used by the backend when creating or refreshing auth sessions.
 */
export const AUTH0_APP_NAME_MOBILEHQ = 'mobilehq';
export const AUTH0_APP_NAME_WEBHQ = ''; // TBC

/**
 * Preset URL configurations for the Ynomia API Gateway.
 */
export const BASE_URL_PRODUCTION = 'https://ynomia.azure-api.net';
export const BASE_URL_STAGING = 'https://ynomia-staging.azure-api.net';
export const BASE_URL_DEVELOPMENT = 'https://ynomia-staging.azure-api.net'; // no dev yet

/**
 * Default configuration object for the `InMemoryCache` class.
 */
export const DEFAULT_IN_MEMORY_CACHE: InMemoryCacheData = {
  baseURL: BASE_URL_DEVELOPMENT,
  clientName: 'generic',
  platform: 'unknown',
  environment: 'development',
  auth: {
    domain: DEFAULT_AUTH0_DOMAIN,
    isAuthenticated: false,
    unauthenticatedReason: 'user_unknown',
  },
};

/**
 * Used in various places to indicate that whilst the current user once had a valid session,
 * it has since been invalidated through either expiration or being manually revoked.
 */
export const ACCESS_REVOKED = 'ACCESS_REVOKED';

/**
 * Default client network request fallback error messages.
 */
export const NETWORK_ERROR_FALLBACK_MESSAGE = 'An internal error occurred.';
export const NETWORK_TIMEOUT_FALLBACK_MESSAGE = 'No response received from the server.';
