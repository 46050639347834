import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { merge } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { skip } from 'rxjs/operators';
import { http as coreHttp } from '@ynomia/core';
import { Bootstrap } from '@ynomia/core/dist/blueprint';
import { AUTH0_JWT_METADATA_KEY, AUTH0_APP_NAME_MOBILEHQ } from './config/constants';
import {
  AuthProvider,
  AuthTokenSet,
  InMemoryCacheData,
  UnauthenticatedReason,
} from './interfaces';
import InMemoryCache from './InMemoryCache';
import {
  decodeJwt,
  defaultHttpHeaders,
  immutableHttpHeaders,
} from './utils';

/**
 * This class is used to initialise and persist authentication sessions for interacting
 * with Ynomia backend services. The implementation for how authentication tokens are
 * fetched initially must be implemented on the specific client. See the `AuthProvider`
 * typescript interface for more information.
 */
export default class SessionManager {
  private cache: InMemoryCache;
  private http: AxiosInstance;
  private onEstablishEmitter: BehaviorSubject<InMemoryCacheData>;
  private onRefreshEmitter: BehaviorSubject<InMemoryCacheData>;
  private onTerminateEmitter: BehaviorSubject<InMemoryCacheData>;

  constructor(cache: InMemoryCache) {
    this.cache = cache;
    this.http = axios.create();
    this.http.interceptors.request.use(this.httpRequestMiddleware);
    this.onEstablishEmitter = new BehaviorSubject(cache.current);
    this.onRefreshEmitter = new BehaviorSubject(cache.current);
    this.onTerminateEmitter = new BehaviorSubject(cache.current);
    this.decodeUserInfo();
  }

  /**
   * Uses the client's custom AuthProvider to authenticate the user and (in some cases)
   * also fetch the bootstrap information from the project service.
   *
   * The currently supported authentication schemes are:
   * - OAuth (requires an additional bootstrap call after authentication)
   * - Simple Auth (email + password)
   *
   * @param {AuthProvider} provider
   * @return {Promise<void>} Resolves when the authentication has completed.
   * @throws {coreHttp.UnauthorisedError}
   */
  async authenticate(provider: AuthProvider): Promise<void> {
    try {
      if ('getTokens' in provider) {
        // OAuth
        const tokens = await provider.getTokens();
        this.cache.assign({
          ...this.cache.current,
          auth: {
            isAuthenticated: true,
            unauthenticatedReason: '',
            accessToken: tokens.accessToken,
            idToken: tokens.idToken,
            refreshToken: tokens.refreshToken,
          },
        });
      } else {
        // Simple Auth
        const loginData = await provider.loginWithBootstrap();
        this.cache.assign({
          ...this.cache.current,
          auth: {
            isAuthenticated: true,
            unauthenticatedReason: '',
            accessToken: loginData.auth.accessToken,
            idToken: loginData.auth.idToken,
            refreshToken: loginData.auth.refreshToken,
          },
          bootstrap: loginData.bootstrap,
          projectId: loginData.bootstrap?.project.metadata.id,
        });
      }
      // Decode the user's ID Token
      this.decodeUserInfo();
      // Event to indicate that the session has been initialized in the client.
      this.onEstablishEmitter.next(this.cache.current);
    } catch (error) {
      throw new coreHttp.UnauthorisedError(
        `Authentication failed: ${error.message}`,
      );
    }
  }

  /**
   * Refreshes the session for a user by using their refresh_token to obtain a new JWT.
   * As a convenience method, we can also re-bootstrap the user and project data.
   * @param {object?} options
   * @param {boolean?} options.bootstrap: Include project and user data in the response?
   * @param {string?} options.projectId: Specify a specific project to bootstrap.
   * @return {Promise<boolean>} `true` if the session renewal was successful.
   */
  async refresh(options?: { bootstrap?: boolean; projectId?: string; }): Promise<boolean> {
    if (!this.cache.current.auth.refreshToken) {
      return false;
    }
    let AUTH0_APP_NAME: string;
    switch (this.cache.current.clientName) {
      case 'mobilehq':
        AUTH0_APP_NAME = AUTH0_APP_NAME_MOBILEHQ;
        break;
      default:
        // Temporary workaround for platforms which don't yet use a single Auth0 application:
        AUTH0_APP_NAME = this.tenant!;
    }
    try {
      const refreshData = await this.http.post(
        `${this.cache.current.baseURL}/scratch/auth/refresh`,
        {
          refreshToken: this.cache.current.auth.refreshToken,
          tenant: AUTH0_APP_NAME,
          ...options,
        },
      );
      this.cache.update({
        auth: {
          isAuthenticated: true,
          accessToken: refreshData.data.value.auth.access_token,
          idToken: refreshData.data.value.auth.id_token,
          refreshToken: refreshData.data.value.auth.refresh_token,
        },
        projectId: refreshData.data.value.bootstrap?.project.metadata.id,
      });
      this.cache.assign({
        bootstrap: refreshData.data.value.bootstrap || this.cache.current.bootstrap,
      });
      this.decodeUserInfo();
      this.onRefreshEmitter.next(this.cache.current);
      return true;
    } catch (error) {
      return false;
    }
  }

  /**
   * Clears the current user's session from the in memory cache.
   * @param {UnauthenticatedReason} reason: Optionally specify the reason why the current session
   * is being destroyed.
   * @return {void}
   */
  destroy(reason: UnauthenticatedReason = 'expired'): void {
    this.cache.assign({
      auth: {
        isAuthenticated: false,
        unauthenticatedReason: reason,
      },
    });
    this.onTerminateEmitter.next(this.cache.current);
  }

  /**
   * Performs a simple login through the Ynomia API Gateway using an email and password.
   * @param {string} email
   * @param {string} password
   * @param {object?} options
   * @param {boolean?} options.bootstrap: Include project and user data in the response?
   * @param {string?} options.projectId: Specify a specific project to bootstrap.
   * @param {string?} options.staySignedIn: Determines if a `refresh_token` is returned or not.
   * @return {Promise<{auth: AuthTokenSet, bootstrap?: Bootstrap}>}
   */
  async simpleLogin(
    email: string,
    password: string,
    options?: {
      bootstrap?: boolean;
      projectId?: string;
      staySignedIn?: boolean;
    },
  ): Promise<{ auth: AuthTokenSet; bootstrap?: Bootstrap; }> {
    const response = await this.http.post(
      `${this.cache.current.baseURL}/scratch/auth/login`,
      {
        email,
        password,
        ...options,
      },
    );
    return {
      auth: {
        accessToken: response.data.value.auth.access_token,
        idToken: response.data.value.auth.id_token,
        refreshToken: response.data.value.auth.refresh_token,
      },
      bootstrap: response.data.value.bootstrap,
    };
  }

  /**
   * Convenience method for ingesting new Bootstrap data into the client cache.
   * @param {Bootstrap} bootstrap
   * @return {void}
   */
  ingestBootstrapData(bootstrap: Bootstrap): void {
    this.cache.assign({
      ...this.cache.current,
      auth: {
        ...this.cache.current.auth,
        isAuthenticated: true,
        unauthenticatedReason: '',
      },
      bootstrap,
      projectId: bootstrap.project?.metadata.id,
    });
  }

  /**
   * Extracts user information from their "ID Token" (returned when signing in).
   * @return {void}
   */
  decodeUserInfo(): void {
    const data = decodeJwt(this.cache.current.auth.idToken);
    this.cache.update({
      auth: {
        userInfo: {
          id: data.sub,
          email: data.email,
          emailVerified: data.email_verified,
          name: data.name,
          picture: data.picture,
          updatedAt: data.updated_at,
          nickname: data.nickname,
          familyName: data.family_name,
          givenName: data.given_name,
        },
      },
    });
  }

  get onEstablish$(): Observable<InMemoryCacheData> {
    return this.onEstablishEmitter.asObservable().pipe(skip(1));
  }

  get onRefresh$(): Observable<InMemoryCacheData> {
    return this.onRefreshEmitter.asObservable().pipe(skip(1));
  }

  get onTerminate$(): Observable<InMemoryCacheData> {
    return this.onTerminateEmitter.asObservable().pipe(skip(1));
  }

  /**
   * Returns the unique tenant identifier for the currently authenticated user.
   */
  get tenant(): string | undefined {
    const jwt = decodeJwt(this.cache.current.auth.accessToken);
    return jwt[AUTH0_JWT_METADATA_KEY]?.tenant;
  }

  /**
   * @private
   * Inserts the default client library HTTP headers to outgoing session requests.
   * @param {AxiosRequestConfig} config: A full Axios request configuration object.
   * @return {AxiosRequestConfig} With any final modifications made.
   */
  private httpRequestMiddleware = (config: AxiosRequestConfig): AxiosRequestConfig => ({
    ...config,
    headers: merge(
      defaultHttpHeaders(this.cache.current),
      config.headers,
      immutableHttpHeaders(this.cache.current),
    ),
  });
}
