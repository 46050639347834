import jwtDecode from 'jwt-decode';
import ObjectIDWrapper from 'bson-objectid';
import { JWT, InMemoryCacheData } from './interfaces';

/**
 * Decodes the middle "payload" portion of a JWT or ID Token.
 * @param {JWT} token
 * @return {object} The parsed data as a plain JavaScript object.
 */
export const decodeJwt = (token?: JWT): { [key: string]: any } => {
  if (!token) return {};
  try {
    const decoded = jwtDecode(token);
    if (!decoded || typeof decoded === 'string') {
      return {};
    }
    return decoded as object;
  } catch (error) {
    return {};
  }
};

/**
 * Returns an object without `null` or `undefined` values.
 * @param {object} obj
 * @return {object}
 */
export const objectWithoutNilValues = (obj: { [x: string]: any } = {}): { [x: string]: any } => {
  const clone = { ...obj };
  Object.keys(clone).forEach((key) => {
    if (clone[key] === undefined || clone[key] === null) {
      delete clone[key];
    }
  });
  return clone;
};

/**
 * Default HTTP request headers to use before other network request configuration is merged.
 * @param {InMemoryCacheData} cache
 * @return {object}
 */
export const defaultHttpHeaders = (cache: InMemoryCacheData): { [x: string]: string } => (
  objectWithoutNilValues({
    'X-Client-Version': cache.version,
    'X-Client-Build-Code': cache.buildCode,
  })
);

/**
 * Generates the non-negotiable HTTP request headers to be sent with each network request.
 * @param {InMemoryCacheData} cache
 * @return {object}
 */
export const immutableHttpHeaders = (cache: InMemoryCacheData): { [x: string]: string } => (
  objectWithoutNilValues({
    Authorization: cache.auth.accessToken ? `Bearer ${cache.auth.accessToken}` : undefined,
    'X-Client-Lib': __VERSION__,
    'X-Client-Name': cache.clientName,
    'X-Client-Platform': cache.platform,
    'X-Client-Environment': cache.environment,
  })
);

/**
 * Generates a unique BSON ObjectID without any MongoDB dependencies.
 */
export const ObjectID = (): string => new ObjectIDWrapper().toHexString();
