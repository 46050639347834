import { merge, assign } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { DEFAULT_IN_MEMORY_CACHE } from './config/constants';
import { RecursivePartial, InMemoryCacheData, InMemoryCacheProvider } from './interfaces';

/**
 * This is an out-of-box InMemoryCache provider that is shipped with this package as an
 * importable module for quick start purposes.
 *
 * Clients are encouraged to implement their own InMemoryCacheProvider's when a more
 * customised and tailored caching solution is required on the platform.
 */
export default class InMemoryCache implements InMemoryCacheProvider {
  private config: InMemoryCacheData;
  private onChangeEmitter: BehaviorSubject<InMemoryCacheData>;

  constructor(config: RecursivePartial<InMemoryCacheData> = {}) {
    const isAuthenticated = config.auth?.isAuthenticated !== undefined
      ? config.auth.isAuthenticated
      : !!config.auth?.accessToken;
    this.config = merge(
      JSON.parse(JSON.stringify(DEFAULT_IN_MEMORY_CACHE)),
      JSON.parse(JSON.stringify(config)),
      JSON.parse(JSON.stringify({
        auth: {
          ...config.auth,
          isAuthenticated,
          unauthenticatedReason: isAuthenticated ? '' : undefined,
        },
      })),
    );
    this.onChangeEmitter = new BehaviorSubject(this.config);
  }

  /**
   * Returns the current value for the `InMemoryCache` configuration object.
   * @return {InMemoryCacheData}
   */
  get current(): InMemoryCacheData {
    return this.config;
  }

  /**
   * Returns an RxJS Observable (event emitter) which is fired each time the in
   * memory cache is updated. You can use this to determine when you need to persist
   * the next version of this data offline on the client.
   * Usage: clientCache.onChange$.subscribe((data: InMemoryCacheData) => ...));
   * @return {Observable<InMemoryCacheData>}
   */
  get onChange$(): Observable<InMemoryCacheData> {
    return this.onChangeEmitter.asObservable();
  }

  /**
   * Merges in new data into the current cache recursively.
   * @param {Partial<InMemoryCacheData>} config
   * @return {void}
   */
  update(config: RecursivePartial<InMemoryCacheData>): void {
    this.config = merge(
      // Must do this for ES5 compiled code since we're doing a merge for `this.x`.
      JSON.parse(JSON.stringify(this.config)),
      JSON.parse(JSON.stringify(config)),
    );
    this.emitChange();
  }

  /**
   * Replaces data in the current cache WITHOUT recursion.
   * @param {Partial<InMemoryCacheData>} config
   * @return {void}
   */
  assign(config: RecursivePartial<InMemoryCacheData>): void {
    this.config = assign(
      // Must do this for ES5 compiled code since we're doing a merge for `this.x`.
      JSON.parse(JSON.stringify(this.config)),
      JSON.parse(JSON.stringify(config)),
    );
    this.emitChange();
  }

  /**
   * Resets the in memory cache to the default values.
   * @param {RecursivePartial<InMemoryCacheData>} config: Optional data to merge with the defaults
   * (same behaviour as the class constructor).
   * @return {void}
   */
  clear(config: RecursivePartial<InMemoryCacheData> = {}): void {
    this.config = merge(
      { ...DEFAULT_IN_MEMORY_CACHE },
      config,
    );
    this.emitChange();
  }

  /**
   * Emits the current config value to the onChange observable listeners.
   * @return {void}
   */
  private emitChange(): void {
    this.onChangeEmitter.next(this.config);
  }
}
